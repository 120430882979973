var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resultadosBusqueda",attrs:{"id":"ResultadosBusqueda"}},[_c('header',{staticClass:"resultadosBusqueda__header mb-5"},[_c('v-container',{staticClass:"resultadosBusqueda__header__criterios mx-auto"},[_c('p',[_vm._v(" "+_vm._s(_vm.busqueda.destino)+" "),_c('img',{attrs:{"src":_vm.icons.purple.mapMarker,"alt":""}}),_c('span',[_vm._v("(Desde "+_vm._s(_vm.busqueda.origen)+")")])]),_c('p',[_vm._v(_vm._s(_vm.ExperienciaOVueloIdaYVueltaOSoloIda))]),_c('p',[_vm._v(_vm._s(_vm.fechasAMostrar))]),_c('p',[_vm._v(_vm._s(_vm.aeronaves)+" - "+_vm._s(_vm.cantidadPasajeros)+" - "+_vm._s(_vm.tipoServicio))]),_c('router-link',{attrs:{"to":{ name: 'CriteriosBusqueda' }}},[_c('Boton',{staticClass:"mt-3",attrs:{"texto":"Editar búsqueda","width":"100%"}})],1)],1)],1),_c('section',{staticClass:"resultadosBusqueda__resultados"},[_c('v-container',{staticClass:"mx-auto"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.esIdaYVuelta)?_c('v-subheader',{staticClass:"resultadosBusqueda__subheader pb-md-2"},[_vm._v(" "+_vm._s(_vm.subheaderIda)+" ")]):_vm._e(),(_vm.busqueda.tipoDeViaje === 'Vuelos' && _vm.cantidadVuelosIda > 0)?_c('v-row',_vm._l((_vm.getVuelos.ida),function(vuelo,i){return _c('v-col',{key:i * 10,staticClass:"resultadosBusqueda__resultados__item pb-4",attrs:{"cols":"12","lg":"6"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioIda),expression:"radioIda"}],attrs:{"type":"radio","id":vuelo.id,"name":"vueloIda","checked":""},domProps:{"value":vuelo,"checked":_vm._q(_vm.radioIda,vuelo)},on:{"change":function($event){_vm.radioIda=vuelo}}}),_c('label',{staticClass:"radioItem",attrs:{"for":vuelo.id}},[_c('CardProximoVuelo',{class:{
                    checkedRadio: _vm.radioIda && _vm.radioIda.id === vuelo.id,
                  },attrs:{"infoVuelo":vuelo}})],1)])}),1):(
              _vm.busqueda.tipoDeViaje === 'Experiencias' && _vm.cantidadVuelosIda > 0
            )?_c('v-row',_vm._l((_vm.getVuelos),function(vuelo,i){return _c('v-col',{key:i * 10,staticClass:"resultadosBusqueda__resultados__item resultadosExperiencias pb-4 d-flex justify-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioIda),expression:"radioIda"}],attrs:{"type":"radio","id":vuelo.id,"name":"vueloIda","checked":""},domProps:{"value":vuelo,"checked":_vm._q(_vm.radioIda,vuelo)},on:{"change":function($event){_vm.radioIda=vuelo}}}),_c('label',{staticClass:"radioItem",attrs:{"for":vuelo.id}},[_c('CardExperiencias',{staticClass:"mx-auto",class:{
                    checkedRadio: _vm.radioIda && _vm.radioIda.id === vuelo.id,
                  },attrs:{"infoExperiencia":vuelo}})],1)])}),1):_c('h2',{staticClass:"px-4 py-8"},[_vm._v(" No hay "+_vm._s(_vm.busqueda.tipoDeViaje.toLowerCase())+" que coincidan con tus criterios de búsqueda ")])],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.esIdaYVuelta)?_c('v-subheader',{staticClass:"resultadosBusqueda__subheader pb-md-2"},[_vm._v("Vuelta")]):_vm._e(),(_vm.esIdaYVuelta && _vm.cantidadVuelosVuelta > 0)?_c('v-row',_vm._l((_vm.getVuelos.vuelta),function(vuelo,i){return _c('v-col',{key:i,staticClass:"resultadosBusqueda__resultados__item pb-4",attrs:{"cols":"12","lg":"6"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioVuelta),expression:"radioVuelta"}],attrs:{"type":"radio","id":vuelo.id + i,"name":"vueloIda","checked":""},domProps:{"value":vuelo,"checked":_vm._q(_vm.radioVuelta,vuelo)},on:{"change":function($event){_vm.radioVuelta=vuelo}}}),_c('label',{staticClass:"radioItem",attrs:{"for":vuelo.id + i}},[_c('CardProximoVuelo',{class:{
                    checkedRadio: _vm.radioVuelta && _vm.radioVuelta.id === vuelo.id,
                  },attrs:{"infoVuelo":vuelo}})],1)])}),1):(_vm.esIdaYVuelta && _vm.cantidadVuelosVuelta == 0)?_c('h2',{staticClass:"px-4 py-8"},[_vm._v(" No hay vuelos que coincidan con tus criterios de búsqueda ")]):_vm._e()],1)],1),_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('div',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('Boton',{attrs:{"texto":"Atrás","width":"100%","color":_vm.colors.disabledGray}})],1)]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"6"}},[_c('div',{on:{"click":_vm.escogerVuelos}},[_c('Boton',{attrs:{"texto":_vm.textoBoton,"width":"100%","deshabilitar":_vm.deshabilitarBoton}})],1)])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }