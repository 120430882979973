<template>
  <div id="ResultadosBusqueda" class="resultadosBusqueda">
    <header class="resultadosBusqueda__header mb-5">
      <v-container class="resultadosBusqueda__header__criterios mx-auto">
        <p>
          {{ busqueda.destino }} <img :src="icons.purple.mapMarker" alt="" />
          <span>(Desde {{ busqueda.origen }})</span>
        </p>
        <p>{{ ExperienciaOVueloIdaYVueltaOSoloIda }}</p>
        <p>{{ fechasAMostrar }}</p>
        <p>{{ aeronaves }} - {{ cantidadPasajeros }} - {{ tipoServicio }}</p>
        <router-link :to="{ name: 'CriteriosBusqueda' }">
          <Boton texto="Editar búsqueda" width="100%" class="mt-3" />
        </router-link>
      </v-container>
    </header>

    <!-- resultados -->
    <section class="resultadosBusqueda__resultados">
      <v-container class="mx-auto">
        <v-row>
          <!-- IDA -->
          <v-col cols="12">
            <!-- Vuelos ida -->
            <v-subheader
              class="resultadosBusqueda__subheader pb-md-2"
              v-if="esIdaYVuelta"
            >
              {{ subheaderIda }}
            </v-subheader>
            <v-row
              v-if="busqueda.tipoDeViaje === 'Vuelos' && cantidadVuelosIda > 0"
            >
              <v-col
                cols="12"
                lg="6"
                v-for="(vuelo, i) in getVuelos.ida"
                :key="i * 10"
                class="resultadosBusqueda__resultados__item pb-4"
              >
                <input
                  type="radio"
                  :id="vuelo.id"
                  name="vueloIda"
                  :value="vuelo"
                  v-model="radioIda"
                  checked
                />
                <label class="radioItem" :for="vuelo.id">
                  <CardProximoVuelo
                    :infoVuelo="vuelo"
                    :class="{
                      checkedRadio: radioIda && radioIda.id === vuelo.id,
                    }"
                  />
                </label>
              </v-col>
            </v-row>
            <!-- Experiencias -->
            <v-row
              v-else-if="
                busqueda.tipoDeViaje === 'Experiencias' && cantidadVuelosIda > 0
              "
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="(vuelo, i) in getVuelos"
                :key="i * 10"
                class="resultadosBusqueda__resultados__item resultadosExperiencias pb-4 d-flex justify-center"
              >
                <input
                  type="radio"
                  :id="vuelo.id"
                  name="vueloIda"
                  :value="vuelo"
                  v-model="radioIda"
                  checked
                />
                <label class="radioItem" :for="vuelo.id">
                  <CardExperiencias
                    :infoExperiencia="vuelo"
                    class="mx-auto"
                    :class="{
                      checkedRadio: radioIda && radioIda.id === vuelo.id,
                    }"
                  />
                </label>
              </v-col>
            </v-row>
            <h2 v-else class="px-4 py-8">
              No hay {{ busqueda.tipoDeViaje.toLowerCase() }} que coincidan con
              tus criterios de búsqueda
            </h2>
          </v-col>

          <!-- Vuelta  -->
          <v-col cols="12">
            <v-subheader
              class="resultadosBusqueda__subheader pb-md-2"
              v-if="esIdaYVuelta"
              >Vuelta</v-subheader
            >
            <v-row v-if="esIdaYVuelta && cantidadVuelosVuelta > 0">
              <v-col
                cols="12"
                lg="6"
                v-for="(vuelo, i) in getVuelos.vuelta"
                :key="i"
                class="resultadosBusqueda__resultados__item pb-4"
              >
                <input
                  type="radio"
                  :id="vuelo.id + i"
                  name="vueloIda"
                  :value="vuelo"
                  v-model="radioVuelta"
                  checked
                />
                <label class="radioItem" :for="vuelo.id + i">
                  <CardProximoVuelo
                    :infoVuelo="vuelo"
                    :class="{
                      checkedRadio: radioVuelta && radioVuelta.id === vuelo.id,
                    }"
                  />
                </label>
              </v-col>
            </v-row>
            <h2
              v-else-if="esIdaYVuelta && cantidadVuelosVuelta == 0"
              class="px-4 py-8"
            >
              No hay vuelos que coincidan con tus criterios de búsqueda
            </h2>
          </v-col>
        </v-row>
        <!-- Botones -->
        <v-container>
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <div @click="$router.go(-1)">
                <Boton
                  texto="Atrás"
                  width="100%"
                  :color="colors.disabledGray"
                />
              </div>
            </v-col>
            <v-col cols="6" class="pl-2">
              <div @click="escogerVuelos">
                <Boton
                  :texto="textoBoton"
                  width="100%"
                  :deshabilitar="deshabilitarBoton"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </section>
  </div>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import CardProximoVuelo from "@/components/Cards/CardProximoVuelo";
import CardExperiencias from "@/components/Cards/CardExperiencias";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "ResultadosBusqueda",
  components: {
    Boton,
    CardProximoVuelo,
    CardExperiencias,
  },
  data() {
    return {
      radioIda: null,
      radioVuelta: null,
    };
  },
  computed: {
    ...mapState(["icons", "colors"]),
    ...mapState("Buscador", ["busqueda"]),
    ...mapState("Vuelos", ["vuelos"]),
    ...mapGetters("Experiencias", ["getExperienciasAMostrar"]),
    experiencias() {
      return this.getExperienciasAMostrar;
    },
    ...mapGetters("Buscador", ["getVuelos"]),
    ExperienciaOVueloIdaYVueltaOSoloIda() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? "Experiencias"
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? "Vuelo solo ida"
        : "Vuelo ida y vuelta";
    },
    fechasAMostrar() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? this.invertirFecha(this.busqueda.fechas.ida)
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? this.invertirFecha(this.busqueda.fechas.ida)
        : `${this.invertirFecha(this.busqueda.fechas.ida)}
            | ${this.invertirFecha(this.busqueda.fechas.vuelta)}`;
    },
    cantidadPasajeros() {
      return this.busqueda.pasajeros > 1
        ? `${this.busqueda.pasajeros} pasajeros`
        : `${this.busqueda.pasajeros} pasajero`;
    },
    aeronaves() {
      return this.busqueda.aeronave.ida === this.busqueda.aeronave.vuelta ||
        this.busqueda.aeronave.vuelta === ""
        ? this.busqueda.aeronave.ida
        : `${this.busqueda.aeronave.ida} | ${this.busqueda.aeronave.vuelta}`;
    },
    tipoServicio() {
      return this.busqueda.tipoDeServicio.ida ===
        this.busqueda.tipoDeServicio.vuelta ||
        this.busqueda.tipoDeServicio.vuelta === ""
        ? this.busqueda.tipoDeServicio.ida
        : `${this.busqueda.tipoDeServicio.ida} | ${this.busqueda.tipoDeServicio.vuelta}`;
    },
    deshabilitarBoton() {
      if (
        this.busqueda.tipoDeVuelo === "Solo ida" ||
        this.busqueda.tipoDeViaje === "Experiencias"
      ) {
        return this.radioIda === null ? true : false;
      } else if (this.busqueda.tipoDeVuelo === "Ida y vuelta") {
        return this.radioIda &&
          this.radioVuelta &&
          this.getVuelos.ida.length >= 1 &&
          this.getVuelos.vuelta.length >= 1
          ? false
          : (this.getVuelos.ida.length === 0 ||
              this.getVuelos.vuelta.length === 0) &&
            (this.radioIda || this.radioVuelta)
          ? false
          : true;
      }
    },
    textoBoton() {
      return !this.deshabilitarBoton
        ? "Continuar"
        : this.busqueda.tipoDeVuelo === "Ida y vuelta"
        ? "Selecciona vuelos"
        : "Selecciona vuelo";
    },
    esIdaYVuelta() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? false
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? false
        : true;
    },
    cantidadVuelosIda() {
      // let cantidad = this.getVuelos.ida.length ? this.getVuelos.ida.length : 0;
      // return cantidad;
      let cantidad = this.getVuelos.length;
      return cantidad;
    },
    cantidadVuelosVuelta() {
      // let cantidad = this.getVuelos.vuelta.length
      //   ? this.getVuelos.vuelta.length
      //   : 0;
      // return cantidad;
      return 0;
    },
    subheaderIda() {
      return this.busqueda.tipoDeViaje === "Experiencias"
        ? "Experiencias"
        : this.busqueda.tipoDeVuelo === "Solo ida"
        ? "Vuelo"
        : "Ida";
    },
  },
  methods: {
    ...mapMutations("Ventas", ["VUELOS_ELEGIDOS"]),
    invertirFecha(fecha) {
      return fecha
        .split("-")
        .reverse()
        .join("-");
    },
    async escogerVuelos() {
      if (!this.deshabilitarBoton) {
        let tipoDeVueloFinal =
          this.radioIda && this.radioVuelta
            ? this.busqueda.tipoDeVuelo
            : "Solo ida";

        let vuelos = {
          ida: this.radioIda ? this.radioIda : this.radioVuelta,

          vuelta: this.radioIda && this.radioVuelta ? this.radioVuelta : null,
        };

        if (tipoDeVueloFinal === "Solo ida") {
          this.$router.push({
            name: "Detalle",
            params: {
              tipoDeViaje: this.busqueda.tipoDeViaje,
              id: vuelos.ida.id,
            },
          });
        } else {
          await this.VUELOS_ELEGIDOS({ ida: null, vuelta: null });
          await this.VUELOS_ELEGIDOS({
            ida: vuelos.ida,
            vuelta: vuelos.vuelta,
            info: {
              tipoDeVuelo: tipoDeVueloFinal,
              tipoDeViaje: this.busqueda.tipoDeViaje,
              pasajeros: this.busqueda.pasajeros,
            },
          });
          this.$router.push({ name: "VuelosEscogidos" });
        }
      }
    },
    buscarVueloPorId(id) {
      const vuelosYExperiencias = this.vuelos.concat(this.experiencias);
      const selected = vuelosYExperiencias.find((v) => v.id === id);
      return selected;
    },
  },
  title() {
    return `Resultados de búsqueda - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#ResultadosBusqueda,
.resultadosBusqueda {
  position: relative;
  min-height: 100vh;
  background-color: $main-white;
  padding-bottom: 83px;
  &__header {
    color: $main-gray;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    padding: 25px 50px 0;
    position: sticky;
    top: -30px;
    z-index: 9999;
    background-color: $main-white;
    &__criterios {
      font-size: 13px;
      p {
        img {
          height: 13px;
        }
        span {
          color: gray;
          font-size: 0.8em;
        }
      }
    }
  }

  &__resultados {
    z-index: 1;
    &__item {
      input[type="radio"] {
        display: none;
      }
    }
  }
  &__subheader {
    background-color: $main-white;
    width: 100%;
    height: 8px;
  }

  .checkedRadio {
    box-shadow: 0 0 10px 3px $main-purple !important;
  }
}

.resultadosExperiencias {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 900px) {
  .resultadosBusqueda {
    &__subheader {
      background-color: $main-white;
    }
  }
}
</style>
